import React from "react"
import styles from "./Footer.module.css"
import categoriesJSON from "../../data/categories.json"
import { Link } from "gatsby"
import NewsletterSubscribe from "../NewsletterSubscribe/NewsletterSubscribe"
import { FaFacebookF } from "@react-icons/all-files/fa/FaFacebookF"
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram"
import { FaTwitter } from "@react-icons/all-files/fa/FaTwitter"
// import { FaDribbble } from "@react-icons/all-files/fa/FaDribbble"
// import { FaPinterest } from "@react-icons/all-files/fa/FaPinterest"
// import { FaTelegram } from "@react-icons/all-files/fa/FaTelegram"
import { FaRegCopyright } from "@react-icons/all-files/fa/FaRegCopyright"
import { navigate } from "gatsby"

const Footer: React.FC = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.content}>
        <div className={styles.left}>
          <div className={styles.section}>
            <div className={`${styles.title} ${styles.sectionTitle}`}>
              Products
            </div>
            <div className={styles.sectionContent}>
              <ul>
                {categoriesJSON.categories.map(item => (
                  <li key={item.id}>
                    <Link to={`/${item.slug}`}>{item.name}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className={styles.section}>
            <div className={`${styles.title} ${styles.sectionTitle}`}>
              Company
            </div>
            <div className={styles.sectionContent}>
              <ul>
                <li>
                  <a href="mailto:hello@palapa.tech">Support</a>
                </li>
                <li>
                  <Link to="/privacy">Privacy policy</Link>
                </li>
                <li>
                  <Link to="/license">License</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.title}>Subscribe to our newsletter</div>
          <div className={styles.newsletterWrap}>
            <NewsletterSubscribe />
          </div>
          <div className={styles.social}>
            <div className={styles.title}>Follow us</div>
            <div className={styles.socialLinks}>
              <a
                href="https://www.facebook.com/palapatechnologies"
                target="_blank"
              >
                <FaFacebookF />
              </a>
              <a href="https://instagram.com/palapa.tech" target="_blank">
                <FaInstagram />
              </a>
              <a href="https://twitter.com/PalapaHQ" target="_blank">
                <FaTwitter />
              </a>
              {/* <a href="#">
                <FaDribbble />
              </a>
              <a href="#">
                <FaPinterest />
              </a>
              <a href="#">
                <FaTelegram />
              </a> */}
            </div>
          </div>
          <button
            onClick={() => {
              navigate("/custom-graphics")
            }}
            className={styles.customBtn}
          >
            Need a custom graphics?
          </button>
        </div>
      </div>
      <div className={styles.copyright}>
        <FaRegCopyright className={styles.copyrightIcon} />{" "}
        {new Date().getFullYear()}, minima.market | made by
        <a href="https://palapa.tech">palapa.tech</a>
      </div>
    </footer>
  )
}

export default Footer
