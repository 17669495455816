import React, { useState } from "react"
import styles from "./NewsletterSubscribe.module.css"
import Swal from "sweetalert2"
import { newsletterSubscribe } from "../../utils/newsletter"

const NewsletterSubscribe: React.FC = () => {
  const [emailValue, setEmailValue] = useState<string>("")
  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    e.stopPropagation()
    newsletterSubscribe(emailValue, "newsletter")
      .then(e => {
        Swal.fire({
          title: "Thank you!",
          text: "Subscribed successfully",
          icon: "success",
          confirmButtonText: "Close",
          confirmButtonColor: "#0019a3",
        })
        setEmailValue("")
      })
      .catch(() => {
        Swal.fire({
          title: "Oops. something gone wrong",
          text: "Please try again or contact us directly via hello@palapa.tech",
          icon: "error",
          confirmButtonText: "Close",
          confirmButtonColor: "#0019a3",
        })
      })
  }

  return (
    <form
      className={styles.newsletter}
      onSubmit={onSubmit}
      name="newsletter"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      method="post"
    >
      <input type="hidden" name="form-name" value="newsletter" />
      <input
        name="email"
        type="email"
        placeholder="Your email"
        value={emailValue}
        onChange={e => setEmailValue(e.target.value)}
      ></input>
      <button type="submit">Subscribe</button>
    </form>
  )
}

export default NewsletterSubscribe
