import { Product } from "./../types/product"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

export const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const price = (amount: number, currency: string = "$") => {
  if (!amount) {
    return "FREE"
  }
  return `${currency}${amount.toFixed(2)}`
}

export const getDownloadUrl = (product: Product) => {
  const BASE_URL = "https://minima-storage.fra1.digitaloceanspaces.com/minima/"
  if (product.key) {
    return `${BASE_URL}${product.id}-${product.key}.zip`
  }
  return `${BASE_URL}${product.id}.zip`
}

export const downloadDemo = (demo: string) => {
  trackCustomEvent({
    category: `[demo] - ${demo}`,
    action: "Click",
    label: `[demo] - ${demo} click`,
  })
  const link = document.createElement("a")
  link.setAttribute("download", `${demo}.zip`)
  link.href = `https://minima-storage.fra1.digitaloceanspaces.com/minima/${demo}.zip`
  document.body.appendChild(link)
  link.click()
  link.remove()
}

export const downloadProduct = (product: Product) => {
  trackCustomEvent({
    category: `[download] - ${product.name}`,
    action: "Click",
    label: `[download] - ${product.name}`,
  })
  const link = document.createElement("a")
  link.setAttribute("download", `${product.name}.zip`)
  link.href = getDownloadUrl(product)
  document.body.appendChild(link)
  link.click()
  link.remove()
}

export const buyProduct = (product: Product) => {
  trackCustomEvent({
    category: `[buy] - ${product.name}`,
    action: "Click",
    label: `[buy] - ${product.name}`,
  })
  const isProd = window.location.host === "minima.market"
  const stripe = (window as any).Stripe(
    isProd
      ? "pk_live_51HNdz1LRGRGFr0QBaGX6UuVJKa8iT8S2vTlXhoRAsMBLWSPMPlpgcga6wYhfHLCad2YSAvtiwgR13O2jbSCPeVhF001qGX7a60"
      : "pk_test_51HNdz1LRGRGFr0QBI7CxUgrX7KzUNJB1L6SudYsGMI6f6Aq28fESdkn6CzfFI6oTrmyPoZoHL0dywvLJaany8Flx00Ok7ABCO5"
  )
  stripe.redirectToCheckout({
    lineItems: [
      {
        price: isProd ? product.stripe_id : product.stripe_dev_id,
        quantity: 1,
      },
    ],
    mode: "payment",
    successUrl:
      window.location.protocol +
      (!isProd
        ? `//localhost:8000/${product.slug}/download/${product.key}`
        : `//minima.market/${product.slug}/download/${product.key}`),
    cancelUrl:
      window.location.protocol +
      (!isProd
        ? `//localhost:8000/${product.slug}`
        : `//minima.market/${product.slug}`),
  })
}

export const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
