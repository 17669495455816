import React, { useEffect, useState } from "react"
import styles from "./Navbar.module.css"
import categoriesJSON from "../../data/categories.json"
import { Link } from "gatsby"
import { IoMenuSharp } from "@react-icons/all-files/io5/IoMenuSharp"
import { IoCloseSharp } from "@react-icons/all-files/io5/IoCloseSharp"
import { IoHomeOutline } from "@react-icons/all-files/io5/IoHomeOutline"
import { IoCubeOutline } from "@react-icons/all-files/io5/IoCubeOutline"
import { IoPhonePortraitOutline } from "@react-icons/all-files/io5/IoPhonePortraitOutline"
import { IoGiftOutline } from "@react-icons/all-files/io5/IoGiftOutline"

const Navbar: React.FC = () => {
  const categories = categoriesJSON.categories
  const [menuVisible, setMenuVisible] = useState(false)
  const closeMenu = () => {
    setMenuVisible(false)
  }
  return (
    <>
      <nav
        className={`${styles.navbar} ${
          menuVisible ? styles.navbar__menuVisible : ""
        }`}
      >
        <div className={styles.navbarInner}>
          <div className={styles.logo}>
            <Link to="/">minima | market</Link>
          </div>
          <button
            className={styles.expandMenuBtn}
            onClick={() => setMenuVisible(!menuVisible)}
          >
            {menuVisible ? <IoCloseSharp /> : <IoMenuSharp />}
          </button>
          <div className={styles.menu} onClick={closeMenu}>
            <ul>
              <li>
                <Link to="/">
                  <div className={styles.menuIcon}>
                    <IoHomeOutline />
                  </div>
                  Home
                </Link>
              </li>
              {categories.map(category => {
                let $icon: React.ReactNode | null = null
                switch (category.id) {
                  case "3d-icons":
                    $icon = <IoCubeOutline />
                    break
                  case "3d-mockups":
                    $icon = <IoPhonePortraitOutline />
                    break
                  case "freebies":
                    $icon = <IoGiftOutline />
                    break
                }
                return (
                  <li key={category.id}>
                    <Link to={`/${category.slug}`}>
                      <div className={styles.menuIcon}>{$icon}</div>
                      {category.name}
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </nav>
      <div className={styles.navbarPlaceholder} />
    </>
  )
}

export default Navbar
