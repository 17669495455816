import Swal from "sweetalert2"
import { encode } from "."

export const newsletterSubscribe = async (
  email: string,
  formName: string,
  successText?: { title: string; message: string },
  errorText: { title: string; message: string } = {
    title: "Oops. something gone wrong",
    message: "Please try again or contact us directly via hello@palapa.tech",
  }
) => {
  const showSuccessMessage = () => {
    if (!successText) {
      return
    }
    Swal.fire({
      title: successText.title,
      text: successText.message,
      icon: "success",
      confirmButtonText: "Close",
      confirmButtonColor: "#0019a3",
    })
  }
  if (location.host.startsWith("localhost")) {
    showSuccessMessage()
    return
  }
  const resp = await fetch("/", {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: encode({
      "form-name": formName,
      email,
    }),
  })
  if (resp.status === 200) {
    showSuccessMessage()
    return
  }
  Swal.fire({
    title: errorText.title,
    text: errorText.message,
    icon: "error",
    confirmButtonText: "Close",
    confirmButtonColor: "#b91515",
  })
  throw resp
}
