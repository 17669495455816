import React from "react"
import PropTypes from "prop-types"

import Navbar from "./../Navbar/Navbar"
import Footer from "./../Footer/Footer"
import "./Layout.css"
import "../../styles/main.css"

const Layout: React.FC = ({ children }) => {
  return (
    <>
      <Navbar />
      <main className={"layout-main"}>{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
